import React, { useCallback, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { useDropzone, Accept } from 'react-dropzone';
import { BsBackspaceFill } from "react-icons/bs";
import getApi from "../api/getApi";
import { BASEURL } from '../constant';

interface Market {
  value: number; // Ensure value is a number
  label: string;
}

interface Event {
  value: string;
  label: string;
}

interface BWDataUploadProps {
  onSubmit: (marketId: number, eventAreaCode: string, files: File[]) => void;
}

const BWDataUpload: React.FC<BWDataUploadProps> = ({ onSubmit }) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileError, setFileError] = useState<string>("");
  const [markets, setMarkets] = useState<Market[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<SingleValue<Market>>(null);
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<SingleValue<Event>>(null);

  const fetchMarkets = async (): Promise<void> => {
    try {
      const role = localStorage.getItem("role") || "";
      const userId = localStorage.getItem("userid") || "";

      const response = await getApi(`${BASEURL}/markets_names?user_id=${userId}&role=${role}`);

      if (response?.data) {
        setMarkets(
          response.data.map((market: { id: number; market: string }) => ({
            value: market.id,
            label: market.market,
          }))
        );
      } else {
        setFileError("No markets found.");
      }
    } catch (error) {
      console.error("Error fetching markets:", error);
      setFileError("Failed to fetch markets.");
    }
  };

  const fetchEvents = async (marketId: number) => {
    try {
      const response = await getApi(`${BASEURL}/get_active_events?market_id=${marketId}`);
      if (response?.data) {
        setEvents(
          response.data.map((event: { EventAreaCode: string; "Event Name": string }) => ({
            value: event.EventAreaCode,
            label: event["Event Name"],
          }))
        );
      }
    } catch (error) {
      setFileError("Failed to fetch events for the selected market.");
    }
  };

  useEffect(() => {
    fetchMarkets();
  }, []);

  useEffect(() => {
    if (selectedMarket) {
      fetchEvents(selectedMarket.value);
    } else {
      setEvents([]);
      setSelectedEvent(null);
    }
  }, [selectedMarket]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 1) {
        setFileError("You can upload only 1 file at a time.");
        return;
      }
      setSelectedFiles(acceptedFiles);
      setFileError("");
    },
    []
  );

  const onSubmitFile = () => {
    setFileError("");
    if (!selectedMarket) {
      setFileError("Please select a market.");
      return;
    }
    if (!selectedEvent) {
      setFileError("Please select an event.");
      return;
    }
    if (selectedFiles.length === 0) {
      setFileError("Please upload a file before submitting.");
      return;
    }
    onSubmit(selectedMarket.value, selectedEvent.value, selectedFiles);
  };

  const acceptTypes: Accept = {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "application/vnd.ms-excel": [".xls"],
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptTypes,
    multiple: false,
  });

  return (
    <div>
      <Form.Group className="mb-3">
        <Form.Label>Select Market</Form.Label>
        <Select
          options={markets}
          value={selectedMarket}
          onChange={(market) => setSelectedMarket(market)}
          placeholder="Select a market"
          isClearable
        />
      </Form.Group>
      {selectedMarket && (
        <Form.Group className="mb-3">
          <Form.Label>Select Active Event</Form.Label>
          <Select
            options={events}
            value={selectedEvent}
            onChange={(event) => setSelectedEvent(event)}
            placeholder="Select an event"
            isClearable
          />
        </Form.Group>
      )}
      <div {...getRootProps()} style={{ border: "1px dashed #ccc", padding: "10px" }}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop an Excel file here, or click to select one. Please upload only 1 file.</p>
      </div>
      {fileError && <p style={{ color: "red" }}>{fileError}</p>}
      <ul>
        {selectedFiles.map((file, index) => (
          <li key={index}>
            {file.name}
            <BsBackspaceFill
              onClick={() => setSelectedFiles([])}
              title="Remove the selected file"
            />
          </li>
        ))}
      </ul>
      <Button className="btn-custom-primary" onClick={onSubmitFile}>
        Submit
      </Button>
    </div>
  );
};

export default BWDataUpload;
